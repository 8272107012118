.login-page {
  background: lightblue;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
}
.login-details {
  background-color: white;
  width: 450px;
  padding: 20px;
  border-radius: 20px;
}
.login-headline,
.login-button {
  text-align: center;
}
.airmart-logo img {
  height: 90px;
}

.loading {
  opacity: 0.5;
}
.detail-wrapper {
  background-color: rgba(226, 217, 217, 0.7);
  margin: 50px auto;
  margin-bottom: 20px;
}
.loading-wrapper {
  height: 90vh;
  margin: 20px auto;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  align-items: center;
}
.airmart-logo {
  text-align: center;
}
.header-logo {
  display: flex;
  justify-content: center;
}
.header-logo img{
  height: 30px;
}
.logout {
  color: white;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 768px)  {
  .login-details {
    width: auto
  }
.airmart-logo img {
  height: 80px;
}
}
.action-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.back-btn {
  background: #001529;
  color: white;
  padding: 9px;
  border-radius: 18px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.print-btn {
  background: #001529;
  color: white;
  padding: 9px;
  border-radius: 18px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

@media print {
  html, body {
    margin: 10px;
  }
  canvas {
      min-height: 100%;
      max-width: 100%;
      max-height: 100%;
      height: auto!important;
      width: auto!important;
  }
}

#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}


#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

#text{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}